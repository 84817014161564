import React from 'react';
import extractTransitColors from '../extract-transit-colors';

export const ManualTransitBand = ({ colors }) => {
  if (!colors.length) {
    return null;
  }

  return (
    <div className="transit-band">
      {colors.map((color, index) => (
        <span key={`${color}-${index}`} style={{ background: color }} />
      ))}
    </div>
  );
};

export default ({ article }) => (
  <ManualTransitBand colors={extractTransitColors(article)} />
);
