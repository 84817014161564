import React from 'react';
import formatRichText from '../format-rich-text';

export const LinkedCaptionsImage = ({ image, caption }) => (
  <div>
    <img
      alt={image.title.toString().replace(/#(floatleft|floatright)/gi, '')}
      width={image.file?.details?.image?.width}
      height={image.file?.details?.image?.height}
      src={image.file?.url || image.fluid?.src}
    />
    <figcaption>{formatRichText(caption)}</figcaption>
  </div>
);
