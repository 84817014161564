import React from 'react';
import { Link } from 'gatsby';

export const BackTo = ({ link, label }) => (
  <div className="back-to">
    <Link to={link}>
      <span className="material-icons md-18">arrow_back</span>
      {label}
    </Link>
  </div>
);
