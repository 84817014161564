import React from 'react';
import { ManualTransitBand } from './transit-band';

export default ({
  routes: rawRoutes,
  distance,
  details,
  tripPlannerUrl,
  isDeal = false,
}) => {
  if (!rawRoutes || !rawRoutes.length) {
    return null;
  }

  const routes = rawRoutes.map(route => {
    const fields = ['color', 'system', 'name'];
    const result = {};
    fields.forEach(field => {
      try {
        result[field] = route[field] || route.fields[field]['en-US'];
      } catch (e) {
        throw new Error(
          `Couldn't find field "${field}" in route: ${JSON.stringify(route)}`
        );
      }
    });
    return result;
  });

  return (
    <div className="transit-info">
      <div className="transit-info__inner">
        <p className="system" data-value={routes[0].system}>
          <img alt="MTA" src="/mta.svg" width="16" height="16" />
          {routes[0].system}
        </p>
        {routes.map(({ color, name, system }) => {
          if (system === 'Subway') {
            return (
              <img
                key={`${color}-${name}-${system}`}
                width="16"
                height="16"
                src={`/lines/${name.toLowerCase()}.svg`}
                alt={name}
                className="route-bullet"
              />
            );
          }

          return (
            <p className="route" key={name}>
              <span className="route-marker" style={{ background: color }} />
              {name}
            </p>
          );
        })}
        {!isDeal && <p className="distance">{distance}</p>}
        {!isDeal && details && (
          <ul className="details">
            {details.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
        )}
      </div>

      <a
        href={
          typeof tripPlannerUrl === 'string'
            ? tripPlannerUrl
            : tripPlannerUrl.childMarkdownRemark.rawMarkdownBody
        }
        rel="noreferrer"
        target="_blank"
        className={isDeal ? '' : 'btn transit-info__trip-planner'}
      >
        {isDeal ? (
          <span class="material-icons md-18">open_in_new</span>
        ) : (
          'Plan Trip'
        )}
      </a>

      {!isDeal && <ManualTransitBand colors={routes.map(r => r.color)} />}
    </div>
  );
};
