import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import TransitInfo from './components/transit-info';
import ContentfulImage from './components/contentful-image';
import { LinkedCaptionsImage } from './components/linked-captions-image';
import fixAssetUrl from './fix-asset-url';
import { ResourceLink } from './components/resource-link';
import { DropdownSection } from './components/dropdown-section';

const translateInternalContentfulFields = fields =>
  Object.fromEntries(
    Object.entries(fields || {}).map(([k, v]) => [k, v['en-US']])
  );

export default json =>
  documentToReactComponents(json, {
    renderText: text =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const contentfulId =
          node?.data?.target?.sys?.contentType?.sys?.contentful_id;
        const fields = translateInternalContentfulFields(
          node.data.target.fields
        );

        if (contentfulId === 'resource') {
          return <ResourceLink {...fields} />;
        }

        if (contentfulId === 'header') {
          return <TransitInfo {...fields} />;
        }

        if (contentfulId === 'adUnit') {
          return (
            <aside>
              <a href={fields.link} target="_blank" rel="noreferrer">
                <ContentfulImage
                  image={translateInternalContentfulFields(fields.asset.fields)}
                />
              </a>
            </aside>
          );
        }
        if (contentfulId === 'dropdownSection') {
          return (
            <DropdownSection title={fields.title} details={fields.details} />
          );
        }

        if (contentfulId === 'linkedCaptionsImage') {
          return (
            <div>
              <LinkedCaptionsImage
                image={translateInternalContentfulFields(fields.file.fields)}
                caption={fields.linkedCaption}
              />
            </div>
          );
        }

        if (contentfulId === 'externalEmbed') {
          return (
            <div
              className={`external-embed external-embed--${fields.appearance.toLowerCase()}`}
              dangerouslySetInnerHTML={{ __html: fields.code }}
            />
          );
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const fields = translateInternalContentfulFields(
          node?.data?.target?.fields
        );

        // do not render broken or incomplete images.
        if (!fields.file || !fields.description || !fields.title) {
          return null;
        }

        const tag = (fields.description?.toString().match(/#([^\s]+)/) ||
          [])[1];
        const description = fields.description
          ?.toString()
          .replace(/#(floatleft|floatright)/gi, '')
          .trim();

        return (
          <figure className={tag ? `figure--${tag}` : ''}>
            <img
              src={fixAssetUrl(fields.file.url)}
              alt={fields.title
                .toString()
                .replace(/#(floatleft|floatright)/gi, '')}
              width={fields.file.details?.image?.width}
              height={fields.file.details?.image?.width}
            />
            {description && description.length && (
              <figcaption>{description}</figcaption>
            )}
          </figure>
        );
      },
    },
  });
