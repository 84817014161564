import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ContentfulImage from './contentful-image';

export default () => {
  const data = useStaticQuery(graphql`
    query SponsorQuery {
      allContentfulHomePage {
        edges {
          node {
            sponsors {
              id
              title
              asset {
                title
                file {
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              link
            }
          }
        }
      }
    }
  `);

  const sponsors = data.allContentfulHomePage.edges?.[0]?.node?.sponsors;
  if (sponsors) {
    // if (!sponsors) { // not using this component as of now
    return null;
  }

  return (
    <section className="sponsors">
      <h3>MTA Away is Supported By</h3>
      <ul className="sponsors__list">
        {sponsors.map(sponsor => (
          <li key={sponsor.id}>
            <a href={sponsor.link} target="_blank" rel="noreferrer">
              <ContentfulImage image={sponsor.asset} />
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};
