import React from 'react';

export default ({ image }) => (
  <img
    alt={image.title.toString().replace(/#(floatleft|floatright)/gi, '')}
    width={image.file?.details?.image?.width}
    height={image.file?.details?.image?.height}
    src={image.file?.url || image.fluid?.src}
  />
);
