import React from 'react';
import formatRichText from '../format-rich-text';
import Sponsors from '../components/sponsors';
import Newsletter from '../components/newsletter';
import '../styles/footer.css';

export default ({ data }) => {
  return (
    <footer>
      <Sponsors />
      <Newsletter />
      {data && (
        <div className="footer">{data && formatRichText(data.json)}</div>
      )}
    </footer>
  );
};
