import React from 'react';

export const DropdownSection = ({ title, details }) => (
  <details className="dropdown-section">
    <summary>
      <span>{title}</span> <div className="dropdown-section__arrow"></div>
    </summary>
    <div dangerouslySetInnerHTML={{ __html: details }} />
  </details>
);
