export default article =>
  (article.body?.json?.content || [])
    .filter(
      node =>
        node?.nodeType === 'embedded-entry-block' &&
        node?.data?.target?.sys?.contentType?.sys?.contentful_id === 'header'
    )
    .map(node =>
      node?.data?.target?.fields?.routes['en-US']?.map(
        r => r?.fields?.color['en-US']
      )
    )
    .flat();
