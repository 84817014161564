export const navigation = [
  {
    title: 'Deals',
    slug: 'deals',
  },
  {
    title: 'Events',
    slug: 'events',
  },
  {
    title: 'Guides',
    slug: 'articles', // TODO: Change to 'guides' when ready
  },
  {
    title: 'About Us',
    slug: 'articles/about-mta-away',
  },
];

export const subnavigation = [
  {
    title: 'Arts+Culture',
    slug: 'arts-culture',
  },
  {
    title: 'Eat+Drink+Shop',
    slug: 'eat-drink-shop',
  },
  {
    title: 'Outdoors',
    slug: 'outdoors',
  },
  {
    title: 'Destinations',
    slug: 'destinations',
  },
  {
    title: 'Browse All',
    slug: 'articles',
  },
];
