const fixAssetUrl = url => {
  if (url) {
    const newUrl = `https:${url
      .replace(/^https?:/, '')
      .replace(/\/downloads\.ctfassets\.net\//, '/images.ctfassets.net/')}`;
    if (!newUrl.includes('?')) {
      return `${newUrl}?w=1180`;
    }
    return newUrl;
  }
  return null;
};

export default fixAssetUrl;
