import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import './base.css';
import '../styles/homepage.css';
import '../styles/articles.css';
import '../styles/article-details.css';
import '../styles/deal.css';
import '../styles/deal-details.css';
import '../styles/pricing-table.css';
import '../styles/events.css';
import '../styles/resources.css';
import '../styles/location-filtering.css';
import '../styles/browse-all.css';
import Footer from '../components/footer';
import { BackTo } from '../components/back-to';
import fixAssetUrl from '../fix-asset-url';
import { navigation, subnavigation } from '../utils/navigation';

export default ({
  children,
  title,
  canonicalUrl,
  mainClass,
  currentSection,
  description: inputDescription,
  image: inputImage,
  backToLink,
  backToLabel,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const navDetails = document.querySelector('nav details');
    if (!navDetails) {
      return;
    }

    const summary = navDetails.querySelector('summary');
    if (!summary) {
      return;
    }

    const isNavOpen = () => window.getComputedStyle(summary).display !== 'none';

    // close the menu automatically on page load
    if (isNavOpen()) {
      navDetails.removeAttribute('open');
    }

    const handleResize = () => {
      // Always show menu on larger screens
      if (!isNavOpen() && window.innerWidth > 768) {
        navDetails.setAttribute('open', '');
      }
      // Autoclose menu on tablet and mobile
      if (isNavOpen() && window.innerWidth <= 768) {
        navDetails.removeAttribute('open');
      }
    };

    // Shrink logo on scroll
    window.onscroll = function () {
      growShrinkLogo();
    };
    function growShrinkLogo() {
      if (window.innerWidth > 760) {
        var Logo = document.getElementById('mta-logo');
        if (
          document.body.scrollTop > 5 ||
          document.documentElement.scrollTop > 5
        ) {
          Logo.style.width = 'auto';
          Logo.style.height = '2.5rem';
          Logo.style.transition = 'width 0.2s, height 0.2s';
        } else {
          Logo.style.width = 'auto';
          Logo.style.height = '4.5rem';
          Logo.style.transition = 'width 0.3s, height 0.3s';
        }
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const NavigationLink = ({ section }) => (
    <Link
      to={`/${section.slug}`}
      className={
        currentSection === section.slug ||
        //due to slug being changed for about-us section
        (section.slug === 'articles/about-mta-away' &&
          currentSection === 'about-us')
          ? 'current'
          : null
      }
    >
      {section.title}
    </Link>
  );

  //deprecated Aug2024
  // const SubNavigation = () => (
  //   <ul>
  //     {subnavigation.map(section => (
  //       <li key={section.slug}>
  //         <NavigationLink section={section} />
  //       </li>
  //     ))}
  //   </ul>
  // );

  const data = useStaticQuery(graphql`
    query CategoriesQuery {
      site {
        siteMetadata {
          title
        }
      }
      contentfulHomePage {
        categories {
          title
          slug
        }
        seoDescription
        sectionCover {
          file {
            url
          }
        }
        footer {
          json
        }
      }
    }
  `);

  const siteTitle = data.site.siteMetadata.title;
  // const sections = data.contentfulHomePage.categories;
  const description =
    inputDescription || data.contentfulHomePage.seoDescription;
  const image =
    inputImage || fixAssetUrl(data.contentfulHomePage.sectionCover?.file?.url);

  const footer = data.contentfulHomePage.footer;

  return (
    <>
      <Helmet title={title ? `${title} | ${siteTitle}` : siteTitle}>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="og:title" content={title ?? siteTitle} />
        <meta name="twitter:title" content={title ?? siteTitle} />
        {description && <meta name="description" content={description} />}
        {description && (
          <meta property="og:description" content={description} />
        )}
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        {image && <meta property="og:image" content={image} />}
        {image && <meta name="twitter:image" content={image} />}
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <script src="/turbolinks.js"></script>
        {/* TODO : need to import 500 font weight for neue haas */}
        <link rel="stylesheet" href="https://use.typekit.net/lbr7neg.css" />
        <link
          rel="icon"
          href={
            process.env.GATSBY_DISPLAY_UNAPPROVED_ENTRIES === 'true'
              ? '/icon-staging.svg'
              : '/icon.svg'
          }
        />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <script src="https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.js" />
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-TE1RYYRE3L"
        />
        <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-TE1RYYRE3L');
      `}</script>
      </Helmet>
      <a className="skip-link" href="#maincontent" data-turbolinks="false">
        Skip to main content
      </a>
      <header onMouseLeave={() => setIsExpanded(false)}>
        <div className="header-inner">
          <h1>
            <Link to="/">
              <img src="/logo.png" alt="MTA Away" id="mta-logo" />
            </Link>
          </h1>
          <nav role="navigation">
            <details open>
              <summary>Menu</summary>
              <ul>
                {navigation.map(section => (
                  <li key={section.slug}>
                    <NavigationLink section={section} />
                    {/* {section.slug === 'things-to-do' ? (
                      <div
                        tabIndex={0}
                        role="button"
                        className="things-to-do"
                        onMouseOver={() => setIsExpanded(true)}
                        onFocus={() => setIsExpanded(true)}
                        onClick={() => navigate('/articles')}
                        onKeyDown={() => navigate('/articles')}
                      >
                        <div
                          className={`nav-title ${
                            isExpanded ? 'expanded' : ''
                          }`}
                        >
                          {section.title}
                        </div>
                        { <div
                          className="chevron"
                          style={{
                            transform: isExpanded
                              ? 'rotate(0)'
                              : 'rotate(180deg)',
                          }}
                        ></div> }
                        { <div className="subnav-mobile">
                          <SubNavigation />
                        </div> }
                      </div>
                    ) : (
                      <NavigationLink section={section} />
                    )} */}
                  </li>
                ))}
              </ul>
            </details>
          </nav>
        </div>
        {/* <div
          className={`header-subnav ${isExpanded ? 'expanded' : 'collapsed'}`}
        >
          <div className="subnav-divider" />
          <div className="header-subnav-inner">
            <SubNavigation />
          </div>
        </div> */}
      </header>
      {backToLink && backToLabel && (
        <BackTo link={backToLink} label={backToLabel} />
      )}
      <main id="maincontent" className={mainClass}>
        {children}
      </main>
      <Footer data={footer} />

      <script src="/base.js"></script>
    </>
  );
};
