import React from 'react';

export default () => (
  <aside className="newsletter">
    {/* <form
      className="newsletter__box"
      action="https://mta.us18.list-manage.com/subscribe/post?u=80933c2dc37752eeb9470b75f&amp;id=3a24dc5ba0"
      method="post"
      target="_blank"
      noValidate
    >
      <label htmlFor="newsletter-email">
        Subscribe to the MTA Away Newsletter:
      </label>
      <div className="newsletter__input">
        <input
          type="email"
          id="newsletter-email"
          name="EMAIL"
          placeholder="Email Address"
          aria-describedby="email-message"
        />
        <button type="submit" className="btn">
          Subscribe
        </button>
      </div>
      <div className="newsletter__message" id="email-message" role="alert" />
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input
          type="text"
          name="b_38164cee8afa25824697b3a48_b48195611f"
          tabIndex="-1"
          defaultValue=""
        />
      </div>
    </form> */}
  </aside>
);
